export const useRiskDefinitionStore = defineStore('riskDefinition', () => {
  const { t } = useI18n()
  const levelMappings: LevelMapping[] = [
    {
      name: t('low'),
      color: 'bg-signal-green-300',
      highlightedColor: 'bg-signal-green-500',
    },
    {
      name: t('medium'),
      color: 'bg-signal-yellow-300',
      highlightedColor: 'bg-signal-yellow-600',
    },
    {
      name: t('high'),
      color: 'bg-signal-orange-300',
      highlightedColor: 'bg-signal-orange-500',
    },
    {
      name: t('very_high'),
      color: 'bg-signal-red-300',
      highlightedColor: 'bg-signal-red-500',
    },
  ]
  const projectStore = useProjectStore()
  const { selectedProject } = storeToRefs(projectStore)
  const riskLevels = ref<RiskLevel[]>([])
  const initializeRiskLevels = () => {
    const levels = levelMappings.map(mapping => ({
      name: mapping.name,
      color: mapping.color,
      highlightedColor: mapping.highlightedColor,
      impactTooltip: false,
      probabilityTooltip: false,
      impactDescriptions: [] as string[],
      probabilityDescriptions: [] as string[],
    }))

    // Populate levels based on definitions from backend
    if (selectedProject.value) {
      const riskDefinitions
        = selectedProject.value.riskValueChain?.definitionSet?.definitions || []
      riskDefinitions.forEach((def) => {
        const levelIndex = def.level - 1
        if (def.type === 'impact') {
          levels[levelIndex]?.impactDescriptions.push(def.criteria)
        }
        else if (def.type === 'probability') {
          levels[levelIndex]?.probabilityDescriptions.push(def.criteria)
        }
      })
    }

    riskLevels.value = levels
  }
  initializeRiskLevels()

  watch(selectedProject, () => {
    initializeRiskLevels()
  })

  return {
    riskLevels,
  }
})
